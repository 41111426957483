import React, { useState } from 'react';
import { Container, Box, Paper } from '@mui/material';
import Translation from './components/Translation';
import Navigation from './components/Navigation';
import Settings from './components/Settings';
import BookSummary from './components/BookSummary';
import Payment from './components/Payment';

type TabType = 'translation' | 'summary' | 'settings' | 'payment';

const App: React.FC = () => {
  const [currentTab, setCurrentTab] = useState<TabType>('translation');

  const renderContent = () => {
    switch (currentTab) {
      case 'translation':
        return <Translation />;
      case 'summary':
        return <BookSummary />;
      case 'settings':
        return <Settings />;
      case 'payment':
        return <Payment />;
      default:
        return <Translation />;
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 2 }}>
      <Paper elevation={3} sx={{ minHeight: '80vh' }}>
        <Box sx={{ p: 2 }}>
          <Navigation currentTab={currentTab} onTabChange={setCurrentTab} />
          {renderContent()}
        </Box>
      </Paper>
    </Container>
  );
};

export default App;
