import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Paper,
  CircularProgress,
  Alert,
  Snackbar,
  Grid
} from '@mui/material';
import WebApp from '@twa-dev/sdk';
import { getPaymentPlans, createInvoice, PaymentPlan } from '../services/api';

const Payment: React.FC = () => {
  const [plans, setPlans] = useState<PaymentPlan[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    loadPaymentPlans();
  }, []);

  const loadPaymentPlans = async () => {
    try {
      setLoading(true);
      const response = await getPaymentPlans();
      setPlans(response.plans);
    } catch (error) {
      setError(error instanceof Error ? error.message : '获取支付计划失败');
    } finally {
      setLoading(false);
    }
  };

  const handlePayment = async (plan: PaymentPlan) => {
    try {
      setLoading(true);
      console.log('Creating invoice for plan:', plan);
      
      // 创建Stars支付发票
      const response = await createInvoice(plan.plan_id);
      console.log('Invoice response:', response);
      
      if (!response.success || !response.invoice_data) {
        throw new Error('创建支付订单失败');
      }

      // 调用Telegram支付
      console.log('Opening invoice with data:', response.invoice_data);
      WebApp.openInvoice(JSON.stringify(response.invoice_data), (status: string) => {
        console.log('Payment status:', status);
        if (status === 'paid') {
          // 支付成功
          WebApp.showPopup({
            title: '支付成功',
            message: plan.duration 
              ? `您已成功订阅${plan.name}，有效期${plan.duration}天`
              : `您已成功购买${plan.name}，获得${plan.quota}次使用额度`,
            buttons: [{ type: 'ok', text: '确定' }]
          });
          
          // 刷新页面
          window.location.reload();
        } else {
          // 支付失败或取消
          console.log('Payment failed or cancelled');
          WebApp.showPopup({
            title: '支付未完成',
            message: '支付未成功完成，请重试',
            buttons: [{ type: 'ok', text: '确定' }]
          });
        }
      });
      
    } catch (error) {
      console.error('Payment error:', error);
      setError(error instanceof Error ? error.message : '支付过程中发生错误');
    } finally {
      setLoading(false);
    }
  };

  if (loading && plans.length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: '100%', mx: 'auto', p: 2 }}>
      <Typography variant="h5" gutterBottom align="center" sx={{ mb: 3 }}>
        Stars支付中心
      </Typography>

      <Typography variant="subtitle1" align="center" sx={{ mb: 4 }}>
        使用 Telegram Stars 购买服务
      </Typography>
      
      <Grid container spacing={2}>
        {/* 订阅套餐 */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            📅 订阅套餐
          </Typography>
        </Grid>
        {plans
          .filter(plan => plan.duration)
          .map((plan) => (
            <Grid item xs={12} key={plan.plan_id}>
              <Paper 
                elevation={2} 
                sx={{ 
                  p: 2,
                  '&:hover': {
                    boxShadow: 3,
                    cursor: 'pointer'
                  }
                }}
                onClick={() => handlePayment(plan)}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box>
                    <Typography variant="h6" gutterBottom>
                      {plan.name}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      {plan.description}
                    </Typography>
                    <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
                      有效期：{plan.duration}天
                    </Typography>
                  </Box>
                  <Box sx={{ textAlign: 'right' }}>
                    <Typography variant="h4" color="primary" gutterBottom>
                      ⭐️ {plan.stars}
                    </Typography>
                    <Button 
                      variant="contained" 
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePayment(plan);
                      }}
                      disabled={loading}
                    >
                      {loading ? <CircularProgress size={24} /> : '立即购买'}
                    </Button>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          ))}

        {/* 单次购买套餐 */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            🎫 单次购买
          </Typography>
        </Grid>
        {plans
          .filter(plan => !plan.duration)
          .map((plan) => (
            <Grid item xs={12} key={plan.plan_id}>
              <Paper 
                elevation={2} 
                sx={{ 
                  p: 2,
                  '&:hover': {
                    boxShadow: 3,
                    cursor: 'pointer'
                  }
                }}
                onClick={() => handlePayment(plan)}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box>
                    <Typography variant="h6" gutterBottom>
                      {plan.name}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      {plan.description}
                    </Typography>
                    <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
                      使用额度：{plan.quota}次
                    </Typography>
                  </Box>
                  <Box sx={{ textAlign: 'right' }}>
                    <Typography variant="h4" color="primary" gutterBottom>
                      ⭐️ {plan.stars}
                    </Typography>
                    <Button 
                      variant="contained" 
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePayment(plan);
                      }}
                      disabled={loading}
                    >
                      {loading ? <CircularProgress size={24} /> : '立即购买'}
                    </Button>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          ))}
      </Grid>

      <Typography 
        variant="caption" 
        color="text.secondary" 
        sx={{ mt: 3, display: 'block', textAlign: 'center' }}
      >
        使用 Telegram Stars 安全支付
      </Typography>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Payment;
