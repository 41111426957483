import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
  Typography,
  Divider,
  CircularProgress,
  Alert,
  Snackbar
} from '@mui/material';
import WebApp from '@twa-dev/sdk';
import { getUserQuota, updateUserSettings } from '../services/api';

interface UserQuota {
  remaining: number;
  total: number;
  subscription_level: string;
}

interface UserSettings {
  notification_enabled: boolean;
  stream_output_enabled: boolean;
  language: string;
  theme: string;
}

const Settings: React.FC = () => {
  const [quota, setQuota] = useState<UserQuota | null>(null);
  const [settings, setSettings] = useState<UserSettings>({
    notification_enabled: true,
    stream_output_enabled: true,
    language: 'zh-CN',
    theme: 'default'
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // 加载用户配额和设置
  useEffect(() => {
    const loadUserData = async () => {
      setIsLoading(true);
      try {
        // 假设我们从Telegram Mini App获取用户ID
        const userId = WebApp.initDataUnsafe?.user?.id;
        if (!userId) throw new Error('未能获取用户信息');

        const quotaResponse = await getUserQuota(userId);
        setQuota(quotaResponse.quota);

      } catch (err) {
        setError(err instanceof Error ? err.message : '加载数据失败');
      } finally {
        setIsLoading(false);
      }
    };

    loadUserData();
  }, []);

  // 处理设置变更
  const handleSettingChange = async (setting: keyof UserSettings) => {
    const newSettings = {
      ...settings,
      [setting]: !settings[setting]
    };

    try {
      const userId = WebApp.initDataUnsafe?.user?.id;
      if (!userId) throw new Error('未能获取用户信息');

      await updateUserSettings(userId, newSettings);
      setSettings(newSettings);
      
      // 触发触觉反馈
      WebApp.HapticFeedback.impactOccurred('light');
    } catch (err) {
      setError(err instanceof Error ? err.message : '更新设置失败');
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: '100%', mx: 'auto', p: 2, mb: 8 }}>
      {/* 配额信息 */}
      <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          配额信息
        </Typography>
        {quota && (
          <>
            <Typography variant="body2">
              订阅等级: {quota.subscription_level}
            </Typography>
            <Typography variant="body2">
              剩余次数: {quota.remaining}/{quota.total}
            </Typography>
          </>
        )}
      </Paper>

      {/* 设置选项 */}
      <Paper elevation={2} sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>
          设置
        </Typography>
        <List>
          <ListItem>
            <ListItemText 
              primary="通知"
              secondary="接收翻译和摘要完成通知"
            />
            <ListItemSecondaryAction>
              <Switch
                edge="end"
                checked={settings.notification_enabled}
                onChange={() => handleSettingChange('notification_enabled')}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="流式输出"
              secondary="实时显示翻译和摘要结果"
            />
            <ListItemSecondaryAction>
              <Switch
                edge="end"
                checked={settings.stream_output_enabled}
                onChange={() => handleSettingChange('stream_output_enabled')}
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Paper>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Settings;
