import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, CircularProgress } from '@mui/material';
import WebApp from '@twa-dev/sdk';
import { translateText, getUserQuota, PaymentPlan, getPaymentPlans } from '../services/api';
import PaymentDialog from './PaymentDialog';

const Translation: React.FC = () => {
  const [text, setText] = useState('');
  const [translation, setTranslation] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [quota, setQuota] = useState<{ remaining: number; total: number } | null>(null);
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<PaymentPlan | null>(null);
  const [plans, setPlans] = useState<PaymentPlan[]>([]);

  useEffect(() => {
    loadUserQuota();
    loadPaymentPlans();
  }, []);

  const loadUserQuota = async () => {
    try {
      const userId = WebApp.initDataUnsafe?.user?.id;
      if (!userId) {
        throw new Error('未能获取用户ID');
      }

      const response = await getUserQuota(userId);
      if (response.success && response.quota) {
        setQuota(response.quota);
      }
    } catch (err) {
      console.error('获取配额失败:', err);
    }
  };

  const loadPaymentPlans = async () => {
    try {
      const response = await getPaymentPlans();
      if (response.success) {
        setPlans(response.plans);
      }
    } catch (err) {
      console.error('获取套餐失败:', err);
    }
  };

  const handleTranslate = async () => {
    if (!text.trim()) return;

    try {
      setLoading(true);
      setError(null);

      const response = await translateText(text);
      if (response.success) {
        setTranslation(response.result);
        // 翻译成功后刷新配额
        await loadUserQuota();
      } else {
        throw new Error('翻译失败');
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : '翻译出错');
      if (err instanceof Error && err.message.includes('配额')) {
        // 如果是配额不足，显示购买对话框
        const basicPlan = plans.find(p => p.plan_id === 'basic');
        if (basicPlan) {
          setSelectedPlan(basicPlan);
          setPaymentDialogOpen(true);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePaymentDialogClose = () => {
    setPaymentDialogOpen(false);
    setSelectedPlan(null);
    // 支付完成后刷新配额
    loadUserQuota();
  };

  return (
    <Box sx={{ p: 2, maxWidth: 600, mx: 'auto' }}>
      <Typography variant="h5" gutterBottom>
        AI翻译助手
      </Typography>
      
      {quota && (
        <Typography variant="body2" color="textSecondary" gutterBottom>
          剩余次数：{quota.remaining} / {quota.total}
        </Typography>
      )}

      <TextField
        fullWidth
        multiline
        rows={4}
        variant="outlined"
        placeholder="请输入要翻译的文本..."
        value={text}
        onChange={(e) => setText(e.target.value)}
        disabled={loading}
        sx={{ mb: 2 }}
      />

      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleTranslate}
        disabled={loading || !text.trim()}
        sx={{ mb: 2 }}
      >
        {loading ? <CircularProgress size={24} /> : '翻译'}
      </Button>

      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      {translation && (
        <TextField
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          value={translation}
          InputProps={{ readOnly: true }}
          sx={{ mb: 2 }}
        />
      )}

      <PaymentDialog
        open={paymentDialogOpen}
        onClose={handlePaymentDialogClose}
        plan={selectedPlan}
      />
    </Box>
  );
};

export default Translation;
