import React from 'react';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import TranslateIcon from '@mui/icons-material/Translate';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SettingsIcon from '@mui/icons-material/Settings';
import PaymentIcon from '@mui/icons-material/Payment';

type TabType = 'translation' | 'summary' | 'settings' | 'payment';

interface NavigationProps {
  currentTab: TabType;
  onTabChange: (tab: TabType) => void;
}

const Navigation: React.FC<NavigationProps> = ({ currentTab, onTabChange }) => {
  return (
    <BottomNavigation
      value={currentTab}
      onChange={(_, newValue) => onTabChange(newValue as TabType)}
      showLabels
      sx={{ mb: 2 }}
    >
      <BottomNavigationAction
        label="翻译"
        value="translation"
        icon={<TranslateIcon />}
      />
      <BottomNavigationAction
        label="总结"
        value="summary"
        icon={<MenuBookIcon />}
      />
      <BottomNavigationAction
        label="升级"
        value="payment"
        icon={<PaymentIcon />}
      />
      <BottomNavigationAction
        label="设置"
        value="settings"
        icon={<SettingsIcon />}
      />
    </BottomNavigation>
  );
};

export default Navigation;
