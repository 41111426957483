import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Paper,
  Typography,
  CircularProgress,
  Alert,
  Snackbar
} from '@mui/material';
import WebApp from '@twa-dev/sdk';

const BookSummary: React.FC = () => {
  const [text, setText] = useState('');
  const [summary, setSummary] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSummarize = async () => {
    if (!text.trim()) return;

    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/summarize`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text }),
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.detail || '生成摘要失败');

      setSummary(data.result);
      WebApp.expand();
    } catch (err) {
      setError(err instanceof Error ? err.message : '发生错误');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ maxWidth: '100%', mx: 'auto', p: 2 }}>
      <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
        <TextField
          fullWidth
          multiline
          rows={6}
          variant="outlined"
          placeholder="输入要总结的文本..."
          value={text}
          onChange={(e) => setText(e.target.value)}
          disabled={isLoading}
          sx={{ mb: 2 }}
        />

        <Button
          fullWidth
          variant="contained"
          onClick={handleSummarize}
          disabled={!text.trim() || isLoading}
          sx={{ mb: 2 }}
        >
          {isLoading ? <CircularProgress size={24} /> : '生成摘要'}
        </Button>

        {summary && (
          <TextField
            fullWidth
            multiline
            rows={6}
            variant="outlined"
            value={summary}
            InputProps={{
              readOnly: true,
            }}
            label="摘要结果"
          />
        )}
      </Paper>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>

      <Typography variant="caption" color="textSecondary" sx={{ mt: 2, display: 'block' }}>
        支持长文本摘要，自动提取关键信息
      </Typography>
    </Box>
  );
};

export default BookSummary;
