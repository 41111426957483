import axios from 'axios';
import WebApp from '@twa-dev/sdk';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://tapp.shijiebi.org';

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export interface TranslationResponse {
  success: boolean;
  result: string;
}

export interface QuotaResponse {
  success: boolean;
  quota: {
    remaining: number;
    total: number;
    subscription_level: string;
  };
}

export interface PaymentPlan {
  plan_id: string;
  name: string;
  stars: number;
  quota?: number;
  duration?: number;
  description: string;
}

export interface PaymentPlansResponse {
  success: boolean;
  plans: PaymentPlan[];
}

export interface InvoiceData {
  title: string;
  description: string;
  payload: string;
  provider_token: string;
  currency: string;
  prices: Array<{
    label: string;
    amount: number;
  }>;
  need_name: boolean;
  need_phone_number: boolean;
  need_email: boolean;
  need_shipping_address: boolean;
  send_phone_number_to_provider: boolean;
  send_email_to_provider: boolean;
  is_flexible: boolean;
}

export interface CreateInvoiceResponse {
  success: boolean;
  order_id: number;
  invoice_data: InvoiceData;
}

export interface UserSettings {
  notification_enabled: boolean;
  stream_output_enabled: boolean;
  language: string;
  theme: string;
}

export const translateText = async (text: string): Promise<TranslationResponse> => {
  try {
    const userId = WebApp.initDataUnsafe?.user?.id;
    if (!userId) {
      throw new Error('未能获取用户ID');
    }

    const response = await api.post<TranslationResponse>('/api/translate', { 
      text,
      user_id: userId
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.detail || '翻译服务出错');
    }
    throw error;
  }
};

export const getUserQuota = async (userId: number): Promise<QuotaResponse> => {
  try {
    const response = await api.get<QuotaResponse>(`/api/user/${userId}/quota`);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.detail || '获取配额信息失败');
    }
    throw error;
  }
};

export const getPaymentPlans = async (): Promise<PaymentPlansResponse> => {
  try {
    const response = await api.get<PaymentPlansResponse>('/api/payment-plans');
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.detail || '获取支付计划失败');
    }
    throw error;
  }
};

export const createInvoice = async (planId: string): Promise<CreateInvoiceResponse> => {
  try {
    const userId = WebApp.initDataUnsafe?.user?.id;
    if (!userId) {
      throw new Error('未能获取用户ID');
    }

    const response = await api.post<CreateInvoiceResponse>('/api/create-invoice', {
      plan_id: planId,
      user_id: userId
    });

    if (!response.data.success) {
      throw new Error('创建支付订单失败');
    }

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.detail || '创建支付订单失败');
    }
    throw error;
  }
};

export const updateUserSettings = async (userId: number, settings: UserSettings): Promise<{ success: boolean }> => {
  try {
    const response = await api.post<{ success: boolean }>(`/api/user/${userId}/settings`, settings);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.detail || '更新设置失败');
    }
    throw error;
  }
};
