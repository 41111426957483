import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Box, CircularProgress } from '@mui/material';
import WebApp from '@twa-dev/sdk';
import { createInvoice, PaymentPlan } from '../services/api';

interface PaymentDialogProps {
  open: boolean;
  onClose: () => void;
  plan: PaymentPlan | null;
}

const PaymentDialog: React.FC<PaymentDialogProps> = ({ open, onClose, plan }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handlePayment = async () => {
    if (!plan) return;
    
    try {
      setLoading(true);
      setError(null);

      // 获取用户ID
      const userId = WebApp.initDataUnsafe?.user?.id;
      if (!userId) {
        throw new Error('未能获取用户ID');
      }

      // 创建支付订单
      const response = await createInvoice(plan.plan_id);
      
      if (!response.success || !response.invoice_data) {
        throw new Error('创建订单失败');
      }

      // 打印发票数据用于调试
      console.log('Invoice data:', JSON.stringify(response.invoice_data, null, 2));

      try {
        // 直接调用Telegram支付
        const invoiceStr = JSON.stringify(response.invoice_data);
        console.log('Opening invoice with data:', invoiceStr);

        // 显示调试信息
        WebApp.showPopup({
          title: '调试信息',
          message: `发票数据：${invoiceStr}`,
          buttons: [
            {
              type: 'ok',
              text: '继续'
            }
          ]
        }, () => {
          try {
            // 尝试打开支付
            WebApp.openInvoice(invoiceStr, (status) => {
              console.log('Payment status:', status);
              if (status === 'paid') {
                WebApp.showAlert('支付成功！');
                onClose();
              } else if (status === 'failed') {
                setError('支付失败，请重试');
              } else if (status === 'cancelled') {
                setError('支付已取消');
              } else {
                setError(`未知的支付状态: ${status}`);
              }
            });
          } catch (err) {
            // 捕获并显示openInvoice的错误
            console.error('Error opening invoice:', err);
            const errorMessage = err instanceof Error ? err.message : String(err);
            console.error('Error details:', {
              error: err,
              message: errorMessage,
              invoiceData: response.invoice_data
            });
            WebApp.showAlert(`打开支付失败: ${errorMessage}`);
            setError(`打开支付失败: ${errorMessage}`);
          }
        });
      } catch (err) {
        // 捕获并显示showPopup的错误
        console.error('Error showing popup:', err);
        const errorMessage = err instanceof Error ? err.message : String(err);
        console.error('Error details:', {
          error: err,
          message: errorMessage,
          invoiceData: response.invoice_data
        });
        WebApp.showAlert(`显示调试信息失败: ${errorMessage}`);
        setError(`显示调试信息失败: ${errorMessage}`);
      }

    } catch (err) {
      // 捕获并显示其他错误
      console.error('Payment error:', err);
      const errorMessage = err instanceof Error ? err.message : String(err);
      console.error('Error details:', {
        error: err,
        message: errorMessage
      });
      WebApp.showAlert(`支付过程中出现错误: ${errorMessage}`);
      setError(`支付过程中出现错误: ${errorMessage}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        确认购买
      </DialogTitle>
      <DialogContent>
        {plan && (
          <Box sx={{ py: 2 }}>
            <Typography variant="h6" gutterBottom>
              {plan.name}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {plan.description}
            </Typography>
            <Typography variant="h5" color="primary" gutterBottom>
              {plan.stars} Stars
            </Typography>
            {error && (
              <Typography color="error" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          取消
        </Button>
        <Button
          onClick={handlePayment}
          variant="contained"
          color="primary"
          disabled={loading}
          startIcon={loading ? <CircularProgress size={20} /> : null}
        >
          立即购买
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentDialog;
